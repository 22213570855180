import React, { useState } from 'react';
import Home from './Home';
import Results from './Results';
import LoginRegister from './LoginRegister';
import SavedSearches from './SavedSearches';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import About from './About';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './Header';

const App = () => {
  const [formState, setFormState] = useState({
    proteinSequence: '',
    predictionMethod: 'netmhciipan_el',
    speciesLocus: '',
    mhcAlleles: [],
    selectedLengths: [],
  });

  const handleFormChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const location = useLocation();
  const isLoginPage = location.pathname === '/loginregister' || location.pathname === '/forgot-password' || location.pathname === '/reset-password';

  return (
    <div>
      {!isLoginPage && <Header />}
      <Routes>
        <Route path="/" element={<Navigate to="/about" />} />
        <Route path="/:type" exact element={<Home formState={formState} onFormChange={handleFormChange} />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/LoginRegister" element={<LoginRegister />} />
        <Route path="/SavedSearches" element={<SavedSearches formState={formState} onFormChange={handleFormChange} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;